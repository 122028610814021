// React
import React from "react";
import PropTypes from "prop-types";

// Constants
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardActions,
  Divider,
  Avatar
} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    // padding: "7px 0px 7px 0px",
    width: "100%",
    marginBottom: 20,
  },
  cardContentContainer: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  cardHeader: {
    cursor: "pointer",
    padding: 0
  },
  cardActions: {
    float: "right",
  },
}));

export default function TeamCard(props) {
  const {uid, onClick, profilePhoto, name, about, cardActions} = props;
  const classes = useStyles(props);

  return (
    <Card classes={{root: classes.root}} id="business-item" key={uid}>
      <CardActionArea onClick={onClick}>
        <CardContent className={classes.cardContentContainer}>
          <CardHeader
            avatar={<Avatar className={classes.avatar} src={profilePhoto}/>}
            title={name}
            classes={{
              subheader: classes.subheader,
            }}
            className={classes.cardHeader}
            subheader={about}
            // onClick={() => onToggleExpand ? onToggleExpand(uid) : console.log("click")}
          />
        </CardContent>
      </CardActionArea>
      {(cardActions && cardActions.length > 0) && <Divider/>}
      {(cardActions && cardActions.length > 0) && (
        <CardActions className={classes.cardActions}>
          {cardActions.map(cardAction => cardAction)}
        </CardActions>
      )}
    </Card>
  );
}

TeamCard.propTypes = {
  freelancer: PropTypes.object.isRequired,
};
