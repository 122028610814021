// React
import React from "react";

// Components
import Link from "../Link";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

// Constants
import * as routes from "../../constants/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "inline-block",
    // marginTop: 50,
    //background: `rgba(${hexToRgb(theme.palette.borders)}, 0.9)`,
  },
  content: {
    maxWidth: 1000,
    margin: "auto",
  },
  logo: {
    margin: 15,
  },
  help: {
    margin: 15,
  },
  rightContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default function Footer(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Divider/>
      <div className={classes.content}>
        <Grid container spacing={0}>
          <Grid item xs={6} sm={6} md={6}>
            <Typography variant="body1" align="left" className={classes.logo}>
              {`© Maxer ${new Date().getFullYear()}`}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <div className={classes.rightContainer}>
              <Typography
                variant="body1"
                align="right"
                className={classes.help}
              >
                <Link to={routes.privacy}>
                  Privacy
                </Link>
              </Typography>
              <Typography
                variant="body1"
                align="right"
                className={classes.help}
              >
                <Link to={routes.termsOfService}>
                  Terms
                </Link>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
